.rtl
{
    direction: rtl;
}
.ltr
{
    direction: ltr;
}
.bg-glare
{
    background-image: url("../image/glare.webp");
    background-position: top center;
    background-repeat: no-repeat;
    background-size: cover;
}



/* STATIC PAGE */
#static-page h2
{
    font-size: 24px;
    font-weight: bold;
    margin-top: 50px;
}
html[lang="ar"] #static-page
{
    text-align: justify;
}



/* HERO BUTTON */
.hero-button-wrapper
{
    position: absolute;

    cursor: pointer;
    width: 164px;
    height: 164px;
    border-radius: 200px;
    -webkit-border-radius: 100px;
    -moz-border-radius: 100px;
    display: -webkit-box;
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: center;
    justify-content: center;
    background: -webkit-linear-gradient(330deg,rgba(255,255,255,0) 0%,rgba(255,255,255,.2) 100%);
    background: linear-gradient(120deg,rgba(255,255,255,0) 0%,rgba(255,255,255,.2) 100%);
    box-shadow: 0 20px 50px 0 rgba(0,0,0,.5);
}
.hero-button-wrapper:hover
{
    cursor: pointer;
    -webkit-transform: scale(1.10);
    transform:scale(1.10);
}
.hero-button-wrapper:hover .hero-button
{
    -webkit-transform: scale(1.12);
    transform:scale(1.12);
}
.hero-button
{
    width: 120px;
    height: 120px;
    border-radius: 200%;
    box-shadow: 0 8px 16px 0 rgba(0,0,0,.3);
    display: -webkit-box;
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: center;
    justify-content: center;
}


/* HERO CONTAINER */
.hero-container
{
    position: relative;
    min-height: 400px;
}
.hero-container::before
{
    content: "";
    display: block;
    position: absolute;
    z-index: -1;
    width: 400px;
    height: 400px;
    border-radius: 200px;
    -moz-border-radius:200px;
    -webkit-border-radius:200px;
    -khtml-border-radius:200px;
    bottom: -80px;
    right: -40px;
}
.hero-container::after
{
    content: "";
    display: block;
    position: absolute;
    z-index: -1;
    width: 50px;
    height: 50px;
    border-radius: 25px;
    -moz-border-radius: 25px;
    -webkit-border-radius: 25px;
    -khtml-border-radius: 25px;
    top: -30px;
    left: 0px;
}

/* GOOGLE MAP */
.gm-style-cc, .gm-style a[href^="https://maps.google.com/maps"]
{
    display: none !important;
}

.map-home-view #google-map-view
{
    -webkit-border-radius: 18px;
    -moz-border-radius: 18px;
    -ms-border-radius: 18px;
    -o-border-radius: 18px;
    border-radius: 18px;
    outline: none !important;
}
.map-home-view #google-map-view div
{
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    -ms-border-radius: 10px;
    -o-border-radius: 10px;
    border-radius: 10px;
    outline: none !important;
}
.gm-style iframe + div
{
    border:none!important; 
}
.gm-body
{
    font-family: #000000;
    color: #ffd400;
    cursor: pointer;
}
.gm-ui-hover-effect
{
    display: none !important;
}
.gm-style .gm-style-iw-c, .gm-style-iw, .gm-style-iw-c
{
    padding:0px !important;
    margin:0px !important;
    background-color: #000000;
}
.gm-style .gm-style-iw-tc::after
{
    background-color: #000000;
}
.gm-style-iw-d
{
    overflow: unset !important;
}



/* SKELTON */
.skeleton
{
    width:100%;
    height: 14px;
}
.skeleton-animation
{
    animation: blinker 1s linear infinite;
}
@keyframes blinker
{
    50%{
      opacity: 0;
    }
}

/* TOOLTIP */
.tooltip
{
    position: relative;
}
.tooltip .tooltipText
{
    visibility: hidden;
    position: absolute;
    z-index: 1;
    opacity: 0;
    top: 100%;
    white-space: wrap;
    text-align: center;
}
.tooltip:hover .tooltipText
{
    visibility: visible;
    opacity: 1;
}

/* DATE PICKER */
.react-datepicker-wrapper
{
    width: 100% !important;
}
.react-datepicker__day--selected,
.react-datepicker__month-text--selected
{
    background-color: #000000 !important;
    color: #ffffff !important;
}
.react-datepicker__day--selected:hover,
.react-datepicker__month-text--selected:hover
{
    background-color: #4a4a4a !important;
    color: #ffffff !important;
}
.react-datepicker-popper
{
    z-index: 2 !important;
}



/* RECHART */
.recharts-sector, .recharts-layer .recharts-pie
{
    outline: none !important;
}



/* MOYASER */
.mysr-form-label, .mysr-form-footer
{
    display: none !important;
}
.mysr-form-button
{
    background-color: #FFD400 !important;
    color: #000000 !important;
    font-size: 14px !important;
    margin-top:20px !important;
}
.mysr-form-inputGroup
{
    margin:0px !important;
}
#form-payment-credit-wallet-rtl #mysr-cc-name
{
    text-align: right;
}
.mysr-form-requiredAlert
{
    display: none !important;
}