@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face
{
    font-family: "font-primary-ar";
    src: local("SuisseIntl-Bold"), url(../font/ar_primary.ttf) format("opentype");
}
@font-face
{
    font-family: "font-secondary-ar";
    src: local("SuisseIntl-Regular"), url(../font/ar_secondary.ttf) format("opentype");
}
@font-face
{
    font-family: "font-primary-en";
    src: local("Effra_Bold"), url(../font/en_primary.ttf) format("opentype");
}
@font-face
{
    font-family: "font-secondary-en";
    src: local("Effra_Rg"), url(../font/en_secondary.ttf) format("opentype");
}

@layer base
{
    html[data-theme="light"]
    {
        --color-primary-lightest: 255,234,128;
        --color-primary-light: 255,225,77;
        --color-primary-regular: 255,212,0;
        --color-primary-dark: 230,191,0;
        --color-primary-darkest: 179,148,0;

        --color-secondary-lightest: 255,255,255;
        --color-secondary-light: 240,240,240;
        --color-secondary-regular: 200,200,200;
        --color-secondary-dark: 38,38,38;
        --color-secondary-darkest: 13, 13, 13;

        --color-accent-lightest: 252, 232, 244;
        --color-accent-light: 241, 142, 201;
        --color-accent-regular: 229, 51, 157;
        --color-accent-dark: 183, 41, 126;
        --color-accent-darkest: 68, 9, 44;

        --color-neutral-high-lightest: 255,255,255;
        --color-neutral-high-light: 237,240,243;
        --color-neutral-high-regular: 221,228,234;
        --color-neutral-high-dark: 201,208,214;
        --color-neutral-high-darkest: 179,191,204;

        --color-neutral-low-lightest: 217,217,217;
        --color-neutral-low-light: 166,166,166;
        --color-neutral-low-regular: 115,115,115;
        --color-neutral-low-dark: 64,64,64;
        --color-neutral-low-darkest: 13,13,13;

        --color-status-success-lightest: 163,220,186;
        --color-status-success-light: 126,206,158;
        --color-status-success-regular: 86,191,129;
        --color-status-success-dark: 63,166,105;
        --color-status-success-darkest: 49,129,82;

        --color-status-danger-lightest: 255,134,121;
        --color-status-danger-light: 235,109,96;
        --color-status-danger-regular: 231,76,60;
        --color-status-danger-dark: 204,42,25;
        --color-status-danger-darkest: 159,33,20;

        --color-status-info-lightest: 78,238,254;
        --color-status-info-light: 27,233,253;
        --color-status-info-regular: 2,217,238;
        --color-status-info-dark: 1,161,177;
        --color-status-info-darkest: 1,115,126;

        --color-backdrop-lightest: 179,191,204;
        --color-backdrop-light: 179,191,204;
        --color-backdrop-regular: 179,191,204;
        --color-backdrop-dark: 179,191,204;
        --color-backdrop-darkest: 179,191,204;

        --color-shadow-lightest: 0,0,0;
        --color-shadow-light: 0,0,0;
        --color-shadow-regular: 0,0,0;
        --color-shadow-dark: 0,0,0;
        --color-shadow-darkest: 0,0,0;

        --color-gradient-lightest: 241,243,248;
        --color-gradient-light: 241,243,248;
        --color-gradient-regular: 241,243,248;
        --color-gradient-dark: 241,243,248;
        --color-gradient-darkest: 241,243,248;
    }
  
    html[data-theme="dark"]
    {
        --color-primary-lightest: 255,234,128;
        --color-primary-light: 255,225,77;
        --color-primary-regular: 255,212,0;
        --color-primary-dark: 230,191,0;
        --color-primary-darkest: 179,148,0;

        --color-secondary-lightest: 255,255,255;
        --color-secondary-light: 240,240,240;
        --color-secondary-regular: 200,200,200;
        --color-secondary-dark: 38,38,38;
        --color-secondary-darkest: 13,13,13;

        --color-accent-lightest: 252, 232,244;
        --color-accent-light: 241,142,201;
        --color-accent-regular: 229,51,157;
        --color-accent-dark: 183, 41, 126;
        --color-accent-darkest: 68,9,44;

        --color-neutral-high-lightest: 34,40,49;
        --color-neutral-high-light: 49,54,63;
        --color-neutral-high-regular: 60,67,77;
        --color-neutral-high-dark: 80,80,90;
        --color-neutral-high-darkest: 104,104,104;

        --color-neutral-low-lightest: 34,40,49;
        --color-neutral-low-light: 209,217,224;
        --color-neutral-low-regular: 249,250,251;
        --color-neutral-low-dark: 252,252,253;
        --color-neutral-low-darkest: 255,255,255;

        --color-status-success-lightest: 163,220,186;
        --color-status-success-light: 126,206,158;
        --color-status-success-regular: 86,191,129;
        --color-status-success-dark: 63,166,105;
        --color-status-success-darkest: 49,129,82;

        --color-status-danger-lightest: 255,134,121;
        --color-status-danger-light: 235,109,96;
        --color-status-danger-regular: 231,76,60;
        --color-status-danger-dark: 204,42,25;
        --color-status-danger-darkest: 159,33,20;

        --color-status-info-lightest: 78,238,254;
        --color-status-info-light: 27,233,253;
        --color-status-info-regular: 2,217,238;
        --color-status-info-dark: 1,161,177;
        --color-status-info-darkest: 1,115,126;

        --color-backdrop-lightest: 13, 13, 13;
        --color-backdrop-light: 13, 13, 13;
        --color-backdrop-regular: 13, 13, 13;
        --color-backdrop-dark: 13, 13, 13;
        --color-backdrop-darkest: 13, 13, 13;

        --color-shadow-lightest: 0,0,0;
        --color-shadow-light: 0,0,0;
        --color-shadow-regular: 0,0,0;
        --color-shadow-dark: 0,0,0;
        --color-shadow-darkest: 0,0,0;

        --color-gradient-lightest: 34,40,49;
        --color-gradient-light: 34,40,49;
        --color-gradient-regular: 34,40,49;
        --color-gradient-dark: 34,40,49;
        --color-gradient-darkest: 34,40,49;
    }

    html[lang="ar"]
    {
        --font-primary: font-primary-ar;
        --font-secondary: font-secondary-ar;
        --font-alt-primary: font-primary-en;
        --font-alt-secondary: font-secondary-en;
    }

    html[lang="en"]
    {
        --font-primary: font-primary-en;
        --font-secondary: font-secondary-en;
        --font-alt-primary: font-primary-ar;
        --font-alt-secondary: font-secondary-ar;
    }
}

html, body
{
    -ms-overflow-style: none;
    scrollbar-width: none;
    box-sizing: border-box;
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
        'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
        sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    overflow-x: clip;
}

::-webkit-scrollbar
{
    display: none;
}